<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="订单号">
            <span style="word-break:break-all">{{ order.order_no }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="订单类型">
            <span style="word-break:break-all">{{ order.order_type_display }}</span>
          </a-descriptions-item>
          <a-descriptions-item v-if="order.order_type === 'add_item'" label="关联殡仪服务订单">
            {{ order.service_order_no }}
          </a-descriptions-item>
          <a-descriptions-item v-if="order.order_type === 'add_item'" label="支付方式">
            {{ order.pay_immediately_display }}
          </a-descriptions-item>
          <a-descriptions-item label="状态">
            {{ order.status_display }}
          </a-descriptions-item>
          <a-descriptions-item label="订单来源">
            {{ order.source }}
          </a-descriptions-item>
          <a-descriptions-item label="负责人">
            {{ order.user_name }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ order.created_at }}
          </a-descriptions-item>
          <a-descriptions-item label="支付单号">
            {{ order.out_trade_no }}
          </a-descriptions-item>
          <a-descriptions-item label="支付途径">
            {{ order.pay_source }}
          </a-descriptions-item>
          <a-descriptions-item label="支付时间">
            {{ order.paid_at }}
          </a-descriptions-item>
          <a-descriptions-item label="所属租户">
            {{ order.tenant_name }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="12">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="下单人">
            {{ order.applicant }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ order.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="收货人">
            {{ order.receiver }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ order.re_phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="目的地">
            {{ order.destination_display }}
          </a-descriptions-item>
          <a-descriptions-item label="殡仪馆" v-if="order.order_type === 'add_item' || order.destination_slug === 'funeral_home'">
            {{ order.funeral_home_name }}
          </a-descriptions-item>
          <a-descriptions-item label="厅房" v-if="order.order_type === 'add_item' || order.destination_slug === 'funeral_home'">
            {{ order.hall_name }}
          </a-descriptions-item>
          <a-descriptions-item label="墓地" v-if="order.destination_slug === 'cemetery'">
            {{ order.cemetery_name }}
          </a-descriptions-item>
          <a-descriptions-item label="殡仪服务点" v-if="order.destination_slug === 'funeral_service_point'">
            {{ order.funeral_service_point_name }}
          </a-descriptions-item>
          <a-descriptions-item label="地址" v-if="order.destination_slug === 'custom'">
            {{ order.address }}
          </a-descriptions-item>
          <a-descriptions-item label="备注" class="remark">
            {{ order.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-row>
      <a-descriptions
        :column="1"
        class="custom-descriptions custom-descriptions-max-10"
      >
        <a-descriptions-item label="结算金额">
          <span style="word-break:break-all">{{ order.settle_fee }}元</span>
        </a-descriptions-item>
        <a-descriptions-item label="实付金额">
          <span style="word-break:break-all">{{ order.paid_fee }}元</span>
        </a-descriptions-item>
      </a-descriptions>
    </a-row>
  </div>
</template>

<script>
import { findSubscribeOrderInfo } from '@/api/order'

export default {
  name: 'SubscribeBasicInformation',
  components: {
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      order: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    orderId() {
      return parseInt(this.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findSubscribeOrderInfo(this.id).then((res) => {
        this.order = res.data
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.custom-blue {
  cursor: pointer;
}
/deep/.remark {
  .ant-descriptions-item {
    display: flex;
    .ant-descriptions-item-label {
      flex-shrink: 0;
    }
  }

}
</style>
